html,
body {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  color: white;
  font-family: "Source Code Pro", Menlo, Monaco, fixed-width;
}

body {
  height: 100%;
  background-color: #2d2d2d;

  display: flex;
  justify-content: center;
  align-items: center;
}
