html, body {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  color: #fff;
  font-family: Source Code Pro, Menlo, Monaco, fixed-width;
}

body {
  height: 100%;
  background-color: #2d2d2d;
  justify-content: center;
  align-items: center;
  display: flex;
}

/*# sourceMappingURL=index.72a2ab11.css.map */
